@use '@/styles/utils/mixins.scss' as *;

.servicesSelectorWrapper {
  max-width: 67.5rem;
  width: 100%;

  .servicesForm {
    display: flex;
    flex-direction: column;
    gap: 3.75rem;

    .servicesFormField {
      display: flex;
      flex-direction: column;
      gap: 2.5rem;

      .vendorBlock {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .categoryTitle {
          font-size: 1.2rem;
          font-weight: bold;
          margin-bottom: 0.5rem;
        }

        .dropdownGrid {
          display: grid;
          grid-template-columns: repeat(2, minmax(250px, 1fr));
          gap: 2rem;

          @include tab() {
            grid-template-columns: 1fr;
          }
        }
      }
    }

    :global {
      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
}
